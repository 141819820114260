.row {
  min-height: 2.5rem !important;
  height: auto !important;
  background-color: transparent !important;
}

.container {
  min-height: 2.5rem !important;
  height: auto !important;
  background-color: transparent !important;
}
