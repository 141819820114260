.title {
  div {
    margin: auto;
  }
}

.modal {
  width: 32.25rem;
  height: 20.375rem;
  position: relative;
}

.err {
  width: 41.25rem;
  height: 38.625rem;
  position: relative;
}

.modaltext {
  text-align: center;
  //   margin-top: 10rem;
  margin-bottom: 1.5rem;
}

.titles {
  display: flex;
  border-bottom: 1px solid #4fb1eb;

  h4 {
    margin-left: 1.5rem;
  }
}

.listbox {
  overflow-y: auto;
  height: 21.25rem;
}

.table {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  align-items: center;
  padding: 0.5rem 0;

  .string {
    margin: 0 1.5rem;
    height: min-content;

    h4 {
      margin: 0;
      width: 3rem;
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1.25rem;
  border-top: 1px solid #ebebeb;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.btn {
  background: #4fb1eb;
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding: 0 2rem;
  border-radius: 0.625rem;
  font-size: 1.125rem;
  color: #fff;
  cursor: pointer;
}

.input {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  visibility: hidden;
  width: 1px;
}

.icon {
  display: block;
  margin: 0.5rem auto;
  text-align: center;
}

.success {
  margin: 4rem auto 0.5rem;
}
