.treeHeader {
  display: flex;
  justify-content: space-between;

  position: sticky;
  top: 0;
  left: 0;
  /* Синхронизировано с .tree */
  width: 35%;
  height: 2.25rem;
  /* Выше полосы с днями */
  z-index: 99;

  background-color: #f4f4f4;
  border-bottom: 1px solid #c3c3c3;

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: 2px;
    height: 100%;
    background: #4fb1eb;
  }
  /* Синхронизировано с .tree */
  &.w7 {
    width: calc(35% + 7rem);
  }
  /* Синхронизировано с .tree */
  &.w22 {
    width: calc(35% + 22rem);
  }
}

.periodFilterText {
  display: flex;
  align-items: center;
  color: #a4a4a4;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.filterInput {
  height: 100%;
  border: none;
  min-width: 3rem;
  order: 2;
  text-overflow: ellipsis;
  padding-right: 2rem;
  padding-left: 0.75rem;
  &:focus ~ .searchIcon {
    path {
      fill: #4fb1eb;
    }
  }
}

.searchIcon {
  order: 1;
  margin-left: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  path {
    fill: #696969;
  }
}

.circleCross {
  position: absolute;
  right: 0.75rem;
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  background-image: url("../../../../../shared/assets/icons/IconClose-small.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.calendarWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.calendar {
  padding-left: 1.5rem;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.date {
  margin-right: 2.25rem;
  font-size: 15px;
  white-space: nowrap;
  cursor: pointer;
}

.days {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #c3c3c3;
  width: 7rem;
}

.period {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #c3c3c3;
  width: 15rem;
}

.flexDaysPeriods {
  display: flex;
  width: auto;
}
