.editingButtonsFieldset {
  display: flex;
  column-gap: 1rem;
  border: 1px solid var(--color-border-secondary);
  border-radius: 0.5rem;
  margin-top: -1.25rem;
  padding: 0.5rem 1rem;
}

.editingButtonsLegend {
  font-size: 1rem;
  color: var(--color-font2);
  width: auto;
  padding: 0 0.25rem;
  margin: 0;
}

.button {
  font-size: 1rem;
}
