.diagramActions {
  min-width: 18rem;
  opacity: 0.9;
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 0.656rem;
}

.actionsContainer {
  position: relative;
  width: 100%;
  min-width: 20rem;
}

.head {
  min-width: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1.25rem;
  height: 2.5625rem;
  border: 1px solid var(--color-border-field18);
  background-color: var(--color-background-main);
  border-radius: 0.75rem;
  padding: 0.6875rem 1.5rem;
  cursor: pointer;
  user-select: none;

  color: var(--color-font4, #696969);
  font-family: var(--main-font);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;

  &.isOpen {
    border-color: #4fb2ed;
    border-radius: 0.5rem 0.5rem 0 0;
    z-index: 10000;
    position: relative;
    border-bottom-color: var(--color-border-field20);
  }

  &.disabled {
    color: #a0a0a0;
    cursor: default;
  }

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .arrow {
    transition: all 0.2s ease;
    width: 1rem;
    margin-left: 0.25rem;
  }

  .arrowReverse {
    transform: rotate(-180deg);
  }

  .isOpen {
    display: block;
  }
}

.content {
  box-sizing: border-box;
  min-width: inherit;
  height: auto;
  top: 2.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
  position: absolute;
  background: #fff;
  z-index: 9999;
  max-height: 25rem;
  overflow-y: auto;
  border: 1px solid var(--color-border-primary);
  padding: 0.25rem 0;
  width: 100%;
}

.actionsGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 1rem;
  padding: 1rem 1.5rem;

  &.center {
    justify-content: center;
    align-items: center;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-border-field20);
  }
}

.actionLabel {
  display: flex;
  align-items: center;
  column-gap: 0.625rem;

  white-space: nowrap;
  color: var(--color-font-main);
  font-weight: 500;
}

.action {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.modeBubble {
  background-color: rgba(75, 75, 75, 0.18);
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.75rem;
  padding: 0.69rem 0.94rem;
  white-space: nowrap;
  cursor: pointer !important;

  & span {
    font-family: var(--title-font);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0;
    text-align: left;
  }
}

.modeBubbleCloseIcon {
  width: 0.625rem;
  height: 0.625rem;
}

.modeBubbleIcon {
  height: 1rem;
}

.outOfEstimateIcon {
  height: 1.2rem;
}

.downloadButton {
  padding: 0.5rem 1rem 0.5rem 1.25rem;
  justify-content: center;
  column-gap: 0.5rem;
  width: 100%;

  color: white;
  font-family: var(--title-font);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.downloadIcon {
  &,
  & path {
    fill: white;
  }

  height: 1rem;
  width: 1rem;
}
