.body {
  padding: 1.188rem;
  border-bottom: 1px solid var(--color-border-field6);
  width: 35rem;
  font-weight: 400;
  min-height: 20rem;
  width: 100%;

  & b {
    font-weight: 500;
  }

  .item {
    margin-top: 0.5rem;
  }
}

.loader {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer {
  padding: 0.786rem 2.357rem;
}

.btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 2rem;
  justify-content: space-between;

  button {
    text-align: center;
    justify-content: center;
  }
}

.modal {
  width: 35rem;
}

.segmentName {
  word-break: break-all;
  color: rgb(150, 150, 150);
  background-color: rgb(235, 235, 235);
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}
