.btn {
  display: flex;
  align-items: center;

  & svg {
    width: 2rem;
    height: 2rem;
    margin: 0 0.5rem;
  }
}

.disabled {
  opacity: 0.7;
  cursor: default;
}

.wide {
  width: 100%;
  background-color: red;
  justify-content: center;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #eff7fc;
  border: 1px solid #d9f1ff;
  color: #4fb1eb;
  font-weight: 500;
}

.solid {
  width: 100%;
  border: 1px solid #d9f1ff;
  border-radius: 0.5rem;
  color: #4fb1eb;
  justify-content: center;
  font-weight: 500;
  background: #eff7fc;

  &:hover {
    color: #fff;
    background-color: #4fb1eb;

    & :global(.addBtnBg) {
      fill: #fff;
    }

    & :global(.addBtnCross) {
      fill: #4fb1eb;
    }
  }
}
