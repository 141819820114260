.addBtn {
  height: 4rem;
}

.container {
  padding: 1rem;
}

.formWrapper {
  border: 1px solid #ebebeb;
  border-radius: 0.5rem;
  background: #fafafa;
  margin-bottom: 0.75rem;
  padding: 0.75rem 1.125rem 1rem 1.125rem;
  min-height: 8rem;
  display: grid;
  row-gap: 1.5rem;
  align-items: center;
  position: relative;
}

.overlaySpinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}
