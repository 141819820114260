@use "src/scss/mixins/_ui-indicators.scss" as ui_indicators;

@import "src/scss/dimensions";
@import "src/scss/mixins/sliceTextByLines";

.container {
  padding: 0.2rem 0.1rem;
  position: relative;
  width: 100%;
  min-height: 8rem;

  &:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }

  .content {
    padding: 1rem;
    min-height: 7.6rem;
    border-radius: 0.5rem;
    align-items: center;
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 1fr 37% 2fr 1fr 1fr 5fr 5fr 5fr;

    &.contentWithActions {
      grid-template-columns: 1fr 37% 2fr 1fr 1fr 3fr 3fr 3fr 2fr;

      & > :global(.relatedWork) {
        grid-column: 4/7;
        grid-row: 1;
      }
    }

    & > div {
      width: 100%;
    }

    & > :global(.relatedWork) {
      grid-column: 4/7;
      grid-row: 1;
    }

    &:hover,
    &.active {
      background-color: #f8f8f8;
    }

    &:hover :global(.addIntervalCol) {
      opacity: 1;
    }
  }
}

.numberCol {
  text-align: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  position: relative;
  top: -0.3375rem;
}

.number {
  margin-bottom: 0.25rem;
}

.infoCol {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.matching {
  margin-left: auto;
}

.countCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.intervalCol {
  position: relative;
}

.actionsCol {
  display: flex;
  justify-content: center;
  margin-left: auto;
  grid-row: 1;
  grid-column: -1;
}

.collapseBtn {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -8px;
  width: 5rem;
  height: 16px;
  background-color: #4fb2ed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  z-index: 2;
}

.expanded {
  height: auto;
  background-color: white;
  z-index: 1;
}

.infoContainer {
  margin-top: 0.5rem;
}

.addIntervalCol {
  opacity: 0;
}

.addIntervalIcon {
  cursor: pointer;
}

.relatedWork {
  margin-right: -33%;
  @include slice-text-by-lines(1, 43%);

  @media screen and (max-width: 1680px) {
    width: 40%;
  }
}

.withoutDiscountIcon {
  opacity: 0.5;
}

.estimatePriceDoubleInput {
  width: 16.25rem;
}

.discount,
.estimatePriceCol {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 1rem;
}

.diffCol {
  display: flex;
}

.diffPercent,
.diffPrice {
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.diffPercent {
  width: 30%;
}

.diffPrice {
  width: 70%;
}

.pricingModeBudget {
  .infoCol {
    width: 42%;
  }

  .priceCol {
    width: 12%;
    text-align: center;
  }

  .discount {
    width: 18%;
  }

  .withDiscountAmount {
    width: 13%;
    text-align: center;
  }

  .countCol {
    width: 12%;
  }
}

.pricingModeEstimatedPrice {
  .infoCol {
    width: 42%;
  }

  .countCol,
  .budgetCol {
    width: 10%;
    text-align: center;
  }

  .diffCol {
    width: 14%;
  }
}

$indicatorSize: 8px;

.withIndicator:before {
  @include ui_indicators.getIndicatorAsContent($indicatorSize);
  position: absolute;
  left: -1rem;
  top: calc(50% + 0.52rem);
  transform: translateY(-50%);
}

.infoWrapper {
  width: 100%;
  padding-right: 3.5rem;
  position: relative;
}

.matching {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: 3.5rem;
}
