.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 1rem;
  padding: 1rem 1.5rem;
}

.actionLabel {
  display: flex;
  align-items: center;
  column-gap: 0.625rem;

  white-space: nowrap;
  color: var(--color-font-main);
  font-weight: 500;
}

.action {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.collapseAllButton {
  width: 100%;
  justify-content: center;
}

.filterEl[class] {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 100%;
  margin: 0 1rem;
}

.indicator {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: var(--color-indicator-frostbite);
  position: absolute;
  top: 0.25rem;
  right: -0.375rem;
}
